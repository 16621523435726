<template>
  <!-- banner图 -->
  <div class="banner">
    <img src="@/assets/image/16523561.png" alt="" />
  </div>
  <!-- tabs -->
  <div class="tabs">
    <div class="tab row juc">
      <div @click="tabClickFun(0)" :class="{ activate: activate === 0 }">
        代理模式
      </div>
      <div @click="tabClickFun(1)" :class="{ activate: activate === 1 }">
        推广模式
      </div>
    </div>
    <div class="showImage">
      <img :src="modelImage" />
    </div>
  </div>
  <!-- 合作支持 -->
  <div class="cooperation">
    <div class="title">合作支持</div>
    <div>5大扶持支撑体系，赋能城市合伙人</div>
    <div class="crads">
      <el-card
        v-for="(item, index) in supportList"
        :key="index"
        :body-style="{ padding: '0px' }"
      >
        <div class="bg">
          <img :src="item.icon" alt="" />
        </div>
        <div class="text omit2" v-html="item.name"></div>
      </el-card>
    </div>
  </div>
  <!-- 扶持政策 -->
  <div class="support">
    <div class="title">扶持政策</div>
    <div>优质的产品线 强大的产品研发力 为您打造坚不可摧的后盾</div>
    <div class="crads">
      <el-card
        v-for="(item, index) in policyList"
        :key="index"
        :body-style="{ padding: '0px' }"
      >
        <div class="bg">
          <img :src="item.icon" alt="" />
        </div>
        <div class="text omit2" v-html="item.name"></div>
      </el-card>
    </div>
  </div>
  <!-- 代理合伙要求 -->
  <div class="need">
    <div class="title">代理合伙要求</div>
    <div>我们需要什么样的伙伴</div>
    <div>
      <div class="showImage"><img src="@/assets/image/5618965.png" /></div>
    </div>
  </div>
  <!-- 团队 -->
  <div class="group">
    <div class="title">强大师资团队</div>
    <div>来自双一流高校 职业考试业内人气讲师</div>
    <div>
      <div class="showImage"><img src="@/assets/image/123456.png" /></div>
    </div>
  </div>
  <!-- 课程 -->
  <div class="curriculum">
    <div class="title">开设课程</div>
    <div>职场提升 财会管理 职业证书 兴趣技能</div>
    <div class="curriculumCrads">
      <el-carousel
        :interval="1000"
        type="card"
        height="200px"
        :autoplay="true"
        indicator-position="none"
      >
        <el-carousel-item v-for="item in coursesList" :key="item">
          <el-card :key="item.name" :body-style="{ padding: '0px' }">
            <div class="bg">
              <img :src="item.cover" alt="图片" />
              <span v-html="item.categoryName"> </span>
            </div>
          </el-card>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
  <!-- 填写信息 -->
  <div class="fill-in">
    <div class="title">
      城市代理商需
      <span class="keyword">5万元以上</span> 资金实力，投资需谨慎
    </div>
    <el-form ref="formValue" :model="form" :rules="rules">
      <el-form-item prop="cityName">
        <el-input v-model="form.cityName" placeholder="请填写所在城市" />
      </el-form-item>
      <el-form-item prop="agentName">
        <el-input v-model="form.agentName" placeholder="请填写真实姓名" />
      </el-form-item>
      <el-form-item prop="corporateName">
        <el-input v-model="form.corporateName" placeholder="请填写公司名称" />
      </el-form-item>
      <el-form-item prop="agentPhone">
        <el-input v-model="form.agentPhone" placeholder="请填写真实手机号" />
      </el-form-item>
      <el-form-item prop="code">
        <el-input
          v-model="form.code"
          placeholder="请输入手机授权码"
        /><auth-code
          class="code"
          text="获取"
          @start="getphoneregcode"
        ></auth-code>
      </el-form-item>
      <el-form-item>
        <el-button class="subnitBtn" @click="onSubmit">立即申请</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { regexPhone, regexChinas } from '@/common/regexp'
import AuthCode from '@/components/AuthCode'
import { addAgency, adList } from '../../api/index'
import { checkPhoneCode, getPhoneCode } from '../../api/login'
import { getProjectList } from '@/api/curriculum'
import { ElMessage } from 'element-plus'
const modelImage = ref('')
const supportList = ref([
  {
    icon: require('../../assets/image/特色.png'),
    name: '系统培训'
  },
  {
    icon: require('../../assets/image/监督管理.png'),
    name: '专业人员 运营指导'
  },
  {
    icon: require('../../assets/image/住宿.png'),
    name: '业务支撑资料'
  },
  {
    icon: require('../../assets/image/答疑.png'),
    name: '盈利的 <br />业务经验指导'
  },
  {
    icon: require('../../assets/image/训练用枪.png'),
    name: '减少试错时间'
  }
])
const policyList = ref([
  {
    icon: require('../../assets/image/用户授权.png'),
    name: '丰富的 产品线授权'
  },
  {
    icon: require('../../assets/image/渠道支持系统.png'),
    name: '专业的渠道支持'
  },
  {
    icon: require('../../assets/image/云平台.png'),
    name: '商务平台支持'
  },
  {
    icon: require('../../assets/image/保护模式.png'),
    name: '业务扶植保护'
  },
  {
    icon: require('../../assets/image/成长.png'),
    name: '助力代理商成长'
  }
])
const coursesList = ref([])
const formValue = ref(null)
const form = ref({
  cityName: '',
  agentName: '',
  corporateName: '',
  agentPhone: '',
  code: ''
})
const activate = ref(0)
const tabImageList = ref([])
const rules = ref({
  cityName: [
    {
      required: true,
      message: '不可为空',
      trigger: ['change']
    }
  ],
  agentName: {
    required: true,
    message: '不可为空',
    trigger: ['change']
  },
  corporateName: [
    {
      required: true,
      message: '不可为空',
      trigger: ['change']
    }
  ],
  agentPhone: [
    {
      required: true,
      message: '不可为空',
      trigger: ['change']
    },
    {
      validator: ({ field }, value, callback) => {
        regexPhone.test(value)
          ? callback()
          : callback(new Error('手机号格式错误'))
      },
      trigger: ['change']
    }
  ],
  code: [
    {
      required: true,
      message: '不可为空',
      trigger: ['change']
    }
  ]
})

getProjectList({ statusId: 1 }).then((res) => {
  coursesList.value = res.rows
})
adList({ statusId: 1 }).then((res) => {
  res.rows.forEach((item) => {
    if (item.adType) {
      tabImageList.value.push(item)
    }
  })
  tabClickFun(0)
})
const tabClickFun = (v) => {
  activate.value = v
  modelImage.value = tabImageList.value[v].adImg
}
// 提交
const onSubmit = () => {
  formValue.value.validate((value) => {
    if (value) {
      checkPhoneCode({
        phone: form.value.agentPhone,
        code: form.value.code
      }).then((isCorrect) => {
        if (isCorrect.data) {
          addAgency({
            ...form.value
          }).then((res) => {
            if (isCorrect.code === 200) {
              ElMessage({
                message: '申请成功，等待工作人员审核。成功后会电话联系您',
                type: 'success'
              })
            }
          })
        } else {
          ElMessage({
            message: '验证码错误',
            type: 'error'
          })
        }
      })
    }
  })
}
const getphoneregcode = () => {
  regexPhone.test(form.value.agentPhone)
    ? getPhoneCode({ phone: form.value.agentPhone }).then((res) => {
        if (res.code === 200) {
          ElMessage({
            message: '发送成功',
            type: 'success'
          })
        }
      })
    : ElMessage({
        message: '请输入正确手机号',
        type: 'error'
      })
}
</script>

<style lang="scss" scoped>
@import '@/common/rem';
.banner {
  width: 100%;
  min-width: rem(1200);
  img {
    width: 100%;
  }
}
.tabs,
.cooperation,
.support,
.need,
.group,
.curriculum {
  width: 1200px;
  min-width: 1200px;
  margin: 0 auto;
  padding: rem(50) 0;
  font-size: 18px;
  line-height: 50px;
  color: #666666;
  text-align: center;
  .title {
    font-size: rem(46);
    font-weight: bold;
  }
  .showImage {
    // height: rem(460);
    margin-top: rem(30);
  }
  .crads {
    display: flex;
    justify-content: space-between;
    .el-card {
      width: rem(220);
      border-radius: rem(10);
      font-size: rem(24);
      font-weight: bold;
      color: #262535;
      .bg {
        display: flex;
        background: #293e73;
        align-content: center;
        justify-content: center;
        vertical-align: middle;
        height: 86px;
        img {
          height: 45px;
          margin: auto;
        }
        color: #ffffff;
      }
      .text {
        width: rem(220);
        height: rem(130);
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        background: url('../../assets/image/z29.png') no-repeat;
      }
    }
  }
}
.need,
.curriculum {
  margin: 0;
  width: 100%;
  background: #f3f5f7;
  .curriculumCrads {
    .el-card {
      width: rem(220);
      border-radius: rem(10);
      font-size: rem(24);
      font-weight: bold;
      color: #262535;
      margin: auto;
      .bg {
        display: flex;
        background: #293e73;
        align-content: center;
        justify-content: center;
        vertical-align: middle;
        height: 86px;
        img {
          height: 45px;
          margin: auto;
        }
        color: #ffffff;
      }
      .text {
        width: rem(220);
        height: rem(130);
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        background: url('../../assets/image/z29.png') no-repeat;
      }
    }
    ::v-deep .el-carousel__mask {
      background-color: transparent;
    }
    ::v-deep .el-carousel__item {
      // height: auto;
      display: flex;
    }
  }
  > div {
    width: rem(1200);
    margin: 0 auto;
    .bg {
      position: relative;
      img {
        width: 100%;
        height: 100% !important;
        margin: 0 !important;
      }
      span {
        top: calc(50% - 1em);
        left: 1em;
        position: absolute;
      }
    }
  }
}
.tabs {
  .tab {
    div {
      width: rem(225);
      height: rem(60);
      color: #333;
      font-size: rem(30);
      box-sizing: border-box;
      background: #ffffff;
      border: 1px solid #6e7eb8;
      border-right: none;
      border-radius: 30px 0px 0px 30px;
      box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.05);
      &:last-child {
        border-radius: 0px 30px 30px 0px;
        border: 1px solid #6e7eb8;
        border-left: none;
      }
    }
    .activate {
      background: #293e73;
      color: #ffffff;
    }
  }
}
.fill-in {
  width: rem(1200);
  margin: 0 auto;
  display: -webkit-flex; /* Safari */
  display: flex;
  text-align: center;
  flex-direction: column;
  .title {
    font-size: rem(30);
    font-weight: bold;
    line-height: 100px;
    .keyword {
      color: #ff0000;
    }
  }
  .el-form {
    width: 600px;
    margin: 0 auto;
    line-height: 28px;
    .el-input {
      height: 60px;
      border-radius: rem(30);
      box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.05);
      ::v-deep input {
        height: 60px;
        border: 1px solid #dadada;
        border-radius: rem(30);
      }
    }
    .code {
      position: absolute;
      top: rem(10);
      right: rem(10);
      width: 100px;
    }
    .subnitBtn {
      width: 100%;
      height: 60px;
      font-size: rem(24);
      color: #ffffff;
      background: #293e73;
      box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.05);
      border-radius: rem(30);
    }
  }
}
</style>
