<template>
  <div class="declare">
    <el-container class="declare-card">
      <el-aside>
        <el-menu
          :default-active="defaultActive"
          class="el-menu-vertical-demo"
          @select="selectMent"
        >
          <el-sub-menu
            :popper-append-to-body="true"
            v-for="mo in menuDate"
            :key="mo.id"
            :index="mo.id + ''"
          >
            <template #title>
              <span>{{ mo.title }}</span>
            </template>
            <el-menu-item
              :popper-append-to-body="false"
              v-for="mt in mo.children"
              :key="mt.id"
              :index="mt.id + ''"
              >{{ mt.bottomTitle }}</el-menu-item
            >
          </el-sub-menu>
        </el-menu>
      </el-aside>
      <el-main>
        <div class="Essay_title title">{{ contetn.articleTitle }}</div>
        <div>
          <div class="editor ql-container ql-snow">
            <div class="ql-editor">
              <div v-html="contetn.articleContent"></div>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script setup>
import { ref, watch } from 'vue'
import {
  Location,
  Document,
  Menu as IconMenu,
  Setting
} from '@element-plus/icons'
import { getEssay, getEssayDetail } from '../../api/index'
import { useRouter } from 'vue-router'

const router = useRouter()
const contetn = ref({})
const is = router.currentRoute.value.query

const defaultActive = ref(is.id + '')
const menuDate = ref([
  {
    id: '44865',
    title: '关于六问',
    children: []
  },
  {
    id: '45116',
    title: '帮助中心',
    children: []
  }
])

const getEssaydetails = (articleId) => {
  getEssayDetail({ articleId }).then((det) => {
    contetn.value = det.rows[0]
  })
}
getEssay().then((res) => {
  res.rows.forEach((item) => {
    if (item.bottomType) {
      menuDate.value[1].children.push(item)
    } else if(item.bottomType!=null){
      menuDate.value[0].children.push(item)
      // getEssaydetails(item.articleId)
    }
    if(defaultActive.value==item.id){
      getEssaydetails(item.articleId)
    }
  })
})

const selectMent = (key, keyPath) => {
  menuDate.value.forEach((item) => {
    if (item.id === keyPath[0]) {
      item.children.forEach((ite) => {
        if (ite.id === parseInt(keyPath[1])) {
          getEssaydetails(ite.articleId)
        }
      })
    }
  })
}
watch(
  () => router.currentRoute.value.query,
  (val) => {
    defaultActive.value = val.id
    console.log(defaultActive.value);
    menuDate.value.forEach((item) => {
      item.children.forEach((items) => {
        if (val.id == items.id) {
          getEssaydetails(items.articleId)
        }
      })
    })
  },
  {
    immediate: true
  }
  
)
</script>
<style lang="scss" scoped>
@import '@/common/rem';
.declare {
  background: #f3f5f7;
  min-height: calc(100vh - (70px + 140px + 260px));
  padding: rem(70) 0;
  .declare-card {
    width: rem(1200);
    margin: 0 auto;
    background: #ffffff;
  }
}
.Essay_title {
  border-bottom: 1px solid #dddddd;
}
</style>
