<template>
  <div class="curriculum-detail">
    <div class="top row">
      <div class="cover">
        <img :src="info.courseLogo" alt="课程图片" />
      </div>
      <div class="recommend">
        <div class="title">{{ info.courseName }}</div>
        <div class="subtitle" v-html="info.introduction"></div>
        <div style="margin-top: 8px;">
          <p>
            主讲老师：<span >{{
              info.lecturerName || '未知'
            }}</span>
          </p>
          <p>
            课时数量：<span >{{
              info.quantity || 0
            }}</span>
          </p>
          <p>
            学习人数：<span >{{
              info.countBuy || 0
            }}</span>
          </p>
        </div>
        <!-- <div v-if="info.isFree" class="price">免费</div>
        <div
          class="flex"
          v-if="!info.isFree && parseFloat(info.courseDiscount) > 0"
        >
          <span class="price"> {{ info.courseDiscount }}</span>
          <span class="discount" v-if="info.courseOriginal">{{
            info.courseOriginal
          }}</span>
        </div>
        <div
          class="price flex"
          v-else-if="!info.isFree && !info.courseDiscount"
        >
          {{ info.courseOriginal }}
        </div> -->

        <div class="opt row">
          <button @click="purchase()" style="cursor: pointer;">
            {{ isMy && store.getters.hasUserInfo ? '去观看' : '立即查看' }}
          </button>
          <div style="margin-right: 100px">
            <el-icon
              @click="enshrine(1, info)"
              v-if="!info.collection"
              :size="26"
              color="#666666"
            >
              <star />
            </el-icon>
            <el-icon
              @click="enshrine(0, info)"
              v-else
              :size="30"
              color="#ff9800"
            >
              <star-filled />
            </el-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom row">
      <div class="left">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <!-- 课程目录 -->
          <el-tab-pane label="课程目录" name="first">
            <div v-if="stretchArr.length > 0">
              <el-collapse v-model="stretch" accordion>
                <el-collapse-item
                  v-for="(s, i) in stretchArr"
                  :key="s.id"
                  :title="s.chapterName"
                  :name="i"
                >
                  <div
                    v-for="c in s.children"
                    :key="c.id"
                    class="row alc"
                    style="line-height: 50px"
                  >
                    <el-icon :size="24" style="margin: auto 20px"
                      ><video-camera
                    /></el-icon>
                    <span style="size: 24px"> {{ c.periodName }}</span>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <div v-else class="lack">
              <svg-icon icon="zanwurenwu" :style="{ fontSize: iconSize }" />
              暂无目录~
            </div>
          </el-tab-pane>
          <!-- 课程详情 -->
          <el-tab-pane label="课程详情" name="second">
            <div style="padding: 20px 0" class="editor ql-container ql-snow">
              <div class="ql-editor">
                <div
                  v-if="info.introduce"
                  v-html="info.introduce || '&nbsp;'"
                ></div>
                <div v-else class="lack">
                  <svg-icon
                    icon="zanwudongtai"
                    :style="{ fontSize: iconSize }"
                  />
                  暂无详情~
                </div>
              </div>
            </div>
          </el-tab-pane>
          <!-- 课程资料 -->
          <el-tab-pane label="课程资料" name="third">
            <div
              v-if="
                dataDownload.length > 0 && store.getters.hasUserInfo && isMy
              "
            >
              <div v-for="d in dataDownload" :key="d.id">
                <div class="file row aic">
                  <svg-icon className="icon" :icon="d.docType || 'unknow'" />
                  <div class="content row aic">
                    <div>
                      <p>{{ d.docName }}</p>
                      <p>
                        上传时间：{{ d.docDate || '无' }}
                        <span
                          >格式：{{
                            d.docType.toUpperCase() || '未知格式'
                          }}</span
                        >
                      </p>
                    </div>
                    <div class="size">
                      {{ d.docSize || '0MB' }}
                    </div>
                  </div>
                  <div class="btn" @click="downloadFlie(d)">下载</div>
                </div>
              </div>
            </div>
            <div v-else>
              <div
                class="lack"
                v-if="!store.getters.hasUserInfo"
                @click="router.push('/login')"
                style="font-size: 20px;"
              >
                请登录后再来查看
              </div>
              <div v-else-if="!isMy" class="lack"  style="font-size: 20px;">请购买后再来查看</div>
              <div v-else class="lack">
                <svg-icon icon="favicon" :style="{ fontSize: iconSize }" />
              <span style="margin-top: 15px;font-size: 20px;">暂无资料~</span>
              </div>
            </div>
          </el-tab-pane>
          <!-- 精华问题 -->
          <el-tab-pane label="精华问题" name="fourth">
            <div v-if="matter.length > 0 && store.getters.hasUserInfo && isMy">
              <el-collapse v-model="stretch" accordion>
                <el-collapse-item
                  v-for="(s, indes) in matter"
                  :key="s.id"
                  :name="indes"
                >
                  <template #title>
                    <div class="omit">{{ s.content }}</div>
                  </template>
                  <div
                    v-for="item in s.children"
                    :key="item.id"
                    class="row aif"
                  >
                    <div class="title omit" style="line-height: 2; width: 3rem">
                      {{ item.proType ? '提问:' : '回答:' }}
                    </div>
                    <div
                      style="line-height: 2; padding-left: 1rem"
                      class="word"
                    >
                      {{ item.content }}
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <div v-else class="lack">
              <div
                v-if="!store.getters.hasUserInfo"
                @click="router.push('/login')"
                style="font-size: 20px;"
              >
                请登录后再来查看
              </div>
              <div v-else-if="!isMy" class="lack" style="font-size: 20px;">请购买后再来查看</div>
              <div v-else class="lack">
                <svg-icon icon="favicon" :style="{ fontSize: iconSize }" />
                            <span style="margin-top: 15px;font-size: 20px;">暂无问题~</span>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="right">
        <div class="title pa-le-10">推荐课程</div>
        <router-link
          v-for="item in coursesList"
          :key="item.id"
          :to="{
            path: '/curriculum/details/' + item.id
          }"
          target="_blank"
        >
          <courses-card
            style="margin: 10px"
            :title="item.courseName"
            :hint="item.countBuy + '人学习'"
            :image="item.courseLogo"
            :price="
              item.isFree ? '免费' : item.courseDiscount || item.courseOriginal
            "
          />
        </router-link>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { Star, StarFilled, Film, VideoCamera } from '@element-plus/icons'
import { ElMessageBox, ElMessage } from 'element-plus'
import coursesCard from '@/components/CoursesCard'
import {
  getCourseDetail,
  getChapterList,
  getChapterPeriodList,
  addCollect,
  getCourseList,
  getProblemList
} from '@/api/curriculum'
import { setItem } from '../../../utils/storage'
import moment from 'moment'
import { delcollect, getMyCourseList, getcollectList } from '@/api/user/index'
import { getQuizDetail } from '../../../api/user'
import store from '../../../store'
import icon from './icon'
const router = useRouter()
const id = router.currentRoute.value.params.id
const info = ref({})
const activeName = ref('first')
const stretch = ref('1')
const stretchArr = ref([])
const dataDownload = ref([])
const matter = ref([])
const coursesList = ref([])
const isMy = ref({})
const videoinfo = ref({})
const iconSize = ref(150)
let reg = /^[0-9]*$/
if (reg.test(id)) {
  getCourseDetail(id).then(async (res) => {
    info.value = res.data || {}
    info.value.quantity = 0
    document.title = info.value.courseName
    let list = await getChapterList({ courseId: id })
    let item = await getChapterPeriodList({ courseId: id })
    info.value.quantity = item.length || 0
    list.forEach((items) => {
      items.children = []
      item.forEach((i) => {
        if (items.id == i.chapterId) {
          items.children.push(i)
          let regName = /[A-Za-z0-9]+/.exec(i.docType)
          if (!regName) regName = ['']
          i.docType = icon(regName[0])
          if (i.docUrl) dataDownload.value.push(i)
        }
      })
    })
    stretchArr.value = list
  })

  getCourseList({ sortType: 1, pageNum: 1, pageSize: 2, statusId: 1 }).then(
    (res) => {
      coursesList.value = res.rows
    }
  )
  if (store.getters.hasUserInfo) {
    getProblemList({ courseId: id }).then((res) => {
      getQuizDetail().then((content) => {
        res.rows.forEach((items) => {
          items.children = []
          content.rows.forEach((item) => {
            if (item.proId === items.id) {
              items.children.push(item)
            }
          })
        })
        matter.value = res.rows
      })
    })
    // api/api/user/course/list?isPutaway=1&pageSize=9&pageNum=1
    getMyCourseList({ courseId: id, isPutaway: 1 }).then((res) => {
      videoinfo.value = res.rows[0]
      getMyCourseList({ courseId: id, overdue: 1 }).then((del) => {
        isMy.value = !(del.rows.length > 0) && videoinfo.value
      })
    })

    getcollectList({ courseId: id }).then((res) => {
      if (res.rows.length > 0) {
        info.value.collection = 1
      } else {
        info.value.collection = 0
      }
    })
  }
} else {
  router.push('/404')
}
const downloadFlie = (d) => {
  if (isMy.value) {
    let link = document.createElement('a')
    link.href = d.docUrl
    let file_name = `${d.docName}__${moment().format('YYYY-MM-DD HH:mm:ss')}`
    link.download = file_name // 下载的时候自定义的文件名
    link.click()
  } else {
    ElMessage({ type: 'error', message: '请先购买课程' })
  }
}

const handleClick = () => {}

const enshrine = (v, val) => {

  info.value.sort = v
  if (!store.getters.hasUserInfo) {
    router.push({
      name: 'login'
    })
    ElMessage({
      message: '请登录',
      type: 'info'
    })
    return
  }
  if (v) {
    addCollect({
      courseId: val.id,
      courseName: val.courseName,
      categoryId: val.categoryId1
    }).then(({ code }) => {
      if (code === 200) info.value.collection = 1
    })
  } else {
    delcollect({ courseId: val.courseId }).then(({ code }) => {
      if (code === 200) info.value.collection = 0
    })
  }
}

const purchase = () => {
  if (!store.getters.hasUserInfo) {
    ElMessageBox.confirm('当前未登录，请登录后查看', '系统提示', {
      confirmButtonText: '去登录',
      cancelButtonText: '取消',
      type: 'warning'
    }).then((res) => {
      if (res === 'confirm') {
        router.push(`/login?redirect=/curriculum/details/${id}`)
      }
    })
  } else if (isMy.value) {
    const video = router.resolve({
      path: '/learn/video'
    })
    setItem('videoInfo', { ...videoinfo.value })
    window.open(video.href, '_parent')
  } else {
    ElMessage({
      message: '暂未拥有该课程1',
      type: 'info'
    })
    //return
    const course = router.resolve({
      path: '/curriculum/purchase/' + info.value.id
    })
    setItem('dfahkhkjsdgfhajhjs', { ...info.value })
    window.open(course.href)
  }
}
</script>
<style lang="scss" scoped>
@import '@/common/rem';
.curriculum-detail {
  background: #f3f5f7;
  min-width: rem(1200);
  padding-bottom: rem(1);
  .top,
  .bottom {
    margin: 0 auto;
    width: rem(1200);
  }
  .top {
    background: #ffffff;
    border-radius: 5px;
    height: rem(430);
    > div {
      margin: rem(40) rem(30);
    }
    .cover {
      height: rem(350);
      overflow: hidden;
      border-radius: 10px;
      // background: linear-gradient(0deg, #1e3c72, #2a5298);
      img {
        // width: 100%;
        height: 100%;
      }
    }margin-top: 5px;
    .recommend {
      font-size: rem(15);
      
      // font-weight: bold;
      width: rem(420);
      color: #333333;
      .title {
        font-size: rem(26);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .subtitle {
        line-height: rem(30);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      p {
        height: 30px;
        // margin-top: 5px;
      }
      .price {
        font-weight: bold;
        font-size: rem(30);
        margin-top: rem(20);
        &::before {
          font-size: rem(18);
        }
      }
      .opt {
        margin-top: rem(20);
        align-items: center;
        justify-content: space-between;
        div {
          margin: auto 0;
        }
        button {
          width: rem(220);
          height: rem(40);
          background: #293e73;
          border-radius: rem(20);
          font-size: 18px;
          font-weight: bold;
          color: #ffffff;
        }
      }
    }
  }
  .bottom {
    margin: rem(20) auto rem(60) auto;
    justify-content: space-between;
    position: relative;
    .left {
      background: #ffffff;
      border-radius: 5px;
      min-height: 670px;
      .el-tabs {
        width: rem(800);
        margin: 0 rem(30);
        ::v-deep .el-tabs__nav-wrap::after {
          position: initial;
        }
        ::v-deep .el-tabs__active-bar {
          height: rem(4);
          background: #293e73;
        }
        ::v-deep .el-tabs__item {
          font-size: rem(20);
          font-weight: bold;
          color: #666666;
        }
        ::v-deep .el-tabs__item:hover,
        ::v-deep .is-active {
          // font-weight: bold;
          color: #293e73;
        }
        .el-collapse,
        .el-collapse-item,
        ::v-deep .el-collapse-item__wrap {
          border: none !important;
        }
        ::v-deep .el-collapse-item__content {
          padding: 0;
          padding-left: 20px;
          font-size: 15px;
        }
        .el-collapse-item {
          margin-bottom: rem(10);
        }
        ::v-deep .el-collapse-item__header {
          background: #f3f5f7;
          border-radius: 5px;
          padding-left: 20px;
         font-size: 15px;
        }
      }
      .file {
        display: flex;
        align-items: center;
        padding: rem(24);
        margin-top: rem(20);
        border-radius: rem(10);
        border: 1px solid #6e7eb8;
        box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.05);
        .icon {
          display: inline-block;
          vertical-align: middle;
          width: rem(35);
          height: rem(35);
        }
        .content {
          flex-grow: 1;
          padding: 0 rem(10);
          justify-content: space-between;
        }
        .btn {
          width: 80px;
          height: 30px;
          text-align: center;
          line-height: rem(30);
          &:hover {
            color: #fff;
          }
        }
      }
    }
    .right {
      max-width: rem(320);
      background: #ffffff;
      border-radius: 5px;
      position: absolute;
      right: 0;
      &::after {
        content: '';
        clear: both;
        display: block;
      }
    }
  }
}
</style>
