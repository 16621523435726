<template>
  <div class="search">
    <div class="searchInput">
      <input
        v-model="queryParams.courseName"
        placeholder="请输入课程名称或者简称"
      />
      <button class="row aic juc" @click="getList">
        <el-icon :size="36"> <search /> </el-icon
        ><span style="margin-left: 1rem">搜索</span>
      </button>
    </div>
    <div class="category">
      <div class="row">
        推荐：
        <el-check-tag
          v-for="(r, i) in recommend"
          :key="r.id"
          :checked="r.checked"
          @change="onChange(r, i, 'recommend')"
          >{{ r.categoryName }}</el-check-tag
        >
      </div>
      <div class="row" style="margin-top: 30px">
        全部：
        <el-check-tag
          v-for="(a, i) in all"
          :key="a.id"
          :checked="a.checked"
          @change="onChange(a, i, 'all')"
          >{{ a.categoryName }}</el-check-tag
        >
      </div>
    </div>
  </div>
  <div class="content">
    <div class="list" v-if="coursesList.length > 0">
      <courses-card
        @click="go(item.id)"
        v-for="item in coursesList"
        style="margin: 10px"
        :key="item.id"
        :hint="item.hint"
        :image="item.courseLogo"
        :title="item.courseName"
        :price="
          item.isFree ? '免费' : item.courseDiscount || item.courseOriginal
        "
      >
      </courses-card>
    </div>
    <div v-else class="lack">
      <svg-icon icon="zanwusousuo" style="font-size: 200px" />
    </div>
    <div class="row" style="justify-content: center">
      <el-pagination
        layout="prev, pager, next"
        :total="total"
        @current-change="alterTotal"
        :page-size="queryParams.pageSize"
      ></el-pagination>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { Search } from '@element-plus/icons'
import coursesCard from '@/components/CoursesCard'
import { getProjectList, getCourseList } from '@/api/curriculum'
const recommend = ref([])
const all = ref([])
const total = ref(0)
const router = useRouter()
const coursesList = ref([])
const queryParams = ref({
  pageNum: 1,
  pageSize: 8,
  statusId: 1
})
const queryTags = ref([])
const getList = () => {
  getCourseList(queryParams.value).then((res) => {
    coursesList.value = res.rows || []
        total.value = res.total
  })
}
// 获取tags列表
const getTabs = () => {
  getProjectList({ statusId: 1, recommend: 1 }).then((res) => {
    let tabs = res.rows
    tabs[0].checked = true
    all.value = tabs
    // total.value = res.total
    tabs.forEach((value) => {
      if (value.recommend) {
        recommend.value.push(value)
      }
    })
    queryParams.value.projectId = [tabs[0].id]
    getList()
  })
}
getTabs()

const onChange = (v, i, k) => {
  all.value[i].checked = !all.value[i].checked
  all.value.forEach((item, index) => {
    if (index != i) {
      item.checked = false
      queryTags.value.splice(queryTags.value.indexOf(item.id), 1)
    }
  })
  if (all.value[i].checked) {
    queryTags.value.push(all.value[i].id)
  }
  queryParams.value.projectId = queryTags.value
  getList()
}

const alterTotal = (pageNum) => {
  queryParams.value.pageNum= pageNum
  getList()
}

const go = (id) => {
  router.push({
    path: '/curriculum/details/' + id
  })
}
</script>

<style lang="scss" scoped>
@import '@/common/rem';
@mixin content {
  width: rem(1200);
  margin: 0 auto;
}
.search {
  padding: rem(50) 0;
  background: #f3f5f7;
  width: 100%;
  min-width: 1200px;
  .searchInput {
    @include content;
    display: flex;
    align-items: center;
    box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.05);
    border-radius: rem(35);
    input {
      height: 70px;
      width: 1030px;
      background: #ffffff;
      border-radius: rem(35) 0 0 rem(35);
      padding-left: rem(45);
      font-size: rem(18);
      &::placeholder {
        // font-weight: 400;
        color: #aaa;
        // line-height: 50px;
      }
    }
    button {
      width: 170px;
      height: 70px;
      background: #293e73;
      border-radius: 0px 35px 35px 0px;
      font-size: rem(24);
      font-weight: bold;
      color: #ffffff;
      line-height: 50px;
    }
  }
  .category {
    @include content;
    font-size: rem(20);
    flex-wrap: wrap;
    font-weight: bold;
    margin-top: rem(90);
    .row {
      align-items: center;
      .el-check-tag {
        box-sizing: border-box;
        margin: 0 rem(5);
        font-size: rem(16);
        color: #333333;
        font-weight: 400;
        background: #ffffff;
        border: 1px dashed #6e7eb8;
      }
      .is-checked {
        border: none;
        color: #ffffff;
        background: #293e73;
      }
    }
  }
}
.content {
  @include content;
  padding: rem(60) 0;
  > .list {
    display: flex;
    flex-wrap: wrap;
    .el-card {
      width: rem(280);
    }
  }
  > .row {
    margin-top: rem(80);
    $blcok: 28px;
    ::v-deep .active {
      color: #ffffff;
      background: #304477;
      height: 100%;
      width: rem($blcok);
      height: rem($blcok);
      border-radius: 50%;
    }
  }
}
</style>
