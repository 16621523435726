import request from '@/utils/request'

// 新增购买课程订单
export function addOrder(data) {
  return request({
    url: '/api/link/order/add',
    method: 'POST',
    data
  })
}
//  修改购买课程订单信息
export function alterOrder(data) {
  return request({
    url: '/api/link/order/edit',
    method: 'POST',
    data
  })
}
// 查询订单信息
export function getOrderStatus(params) {
  return request({
    url: '/api/link/order/info',
    method: 'GET',
    params
  })
}
