const chinaDatas = [
  [
    {
      name: '甘肃',
      value: [98.77753991113792, 36.53004669909589]
    }
  ],
  [
    {
      name: '青海',
      value: [108.948024, 34.263161]
    }
  ],
  [
    {
      name: '广西',
      value: [107.99655439706783, 23.735673935703687]
    }
  ],
  [
    {
      name: '贵州',
      value: [106.25837527859625, 26.505908922458815]
    }
  ],
  [
    {
      name: '重庆',
      value: [106.59396202962392, 29.737597968171656]
    }
  ],
  [
    {
      name: '北京',
      value: [116.35679568867022, 40.25702627244448]
    }
  ],
  [
    {
      name: '福建',
      value: [117.7802840500002, 26.617417710000097]
    }
  ],
  [
    {
      name: '安徽',
      value: [116.62978356256133, 32.13288035704295]
    }
  ],
  [
    {
      name: '广东',
      value: [112.38982181100027, 23.09589264500019]
    }
  ],
  [
    {
      name: '西藏',
      value: [91.67781334810746, 29.081958115774455]
    }
  ],
  [
    {
      name: '新疆',
      value: [86.36633990098284, 42.722922619141826]
    }
  ],
  [
    {
      name: '海南',
      value: [109.68506920700003, 19.15330638200004]
    }
  ],
  [
    {
      name: '宁夏',
      value: [106.65764611237813, 37.85293528913229]
    }
  ],
  [
    {
      name: '陕西',
      value: [108.11004520082531, 33.38519318281914]
    }
  ],
  [
    {
      name: '山西',
      value: [111.72104861939818, 36.35586904611952]
    }
  ],
  [
    {
      name: '湖北',
      value: [111.37402265791923, 31.417539985382007]
    }
  ],
  [
    {
      name: '湖南',
      value: [110.902381220417, 28.066339830418826]
    }
  ],
  [
    {
      name: '四川',
      value: [103.4985530948494, 30.67739736629541]
    }
  ],
  [
    {
      name: '云南',
      value: [100.29696333219198, 25.721744899807277]
    }
  ],
  [
    {
      name: '河北',
      value: [115.64873628100008, 38.04465116700004]
    }
  ],
  [
    {
      name: '河南',
      value: [113.46032230023388, 34.15787079530108]
    }
  ],
  [
    {
      name: '辽宁',
      value: [123.35254967500032, 40.48240794500012]
    }
  ],
  [
    {
      name: '山东',
      value: [118.28029535679576, 36.076608741968954]
    }
  ],
  [
    {
      name: '天津',
      value: [117.65956331411487, 39.21855181203543]
    }
  ],
  [
    {
      name: '江西',
      value: [115.17473025869447, 27.407869370774904]
    }
  ],
  [
    {
      name: '江苏',
      value: [120.34094130672383, 32.49093327643905]
    }
  ],
  [
    {
      name: '上海',
      value: [121.64094130672383, 31.02093327643905]
    }
  ],
  [
    {
      name: '浙江',
      value: [120.28535242000021, 29.10194563100012]
    }
  ],
  [
    {
      name: '吉林',
      value: [126.25284846284336, 43.46916859112878]
    }
  ],
  [
    {
      name: '内蒙古',
      value: [108.41327478505161, 40.317334824121446]
    }
  ],
  [
    {
      name: '黑龙江',
      value: [131.25284846284336, 46.46916859112878]
    }
  ],
  [
    {
      name: '香港',
      value: [114.0517684250002, 22.32851797100014]
    }
  ],
  [
    {
      name: '澳门',
      value: [111.95860436300031, 21.8]
    }
  ],
  [
    {
      name: '台湾',
      value: [120.63599694100014, 23.222805080000114]
    }
  ]
]

const convertData = (data) => {
  var res = []
  data.forEach((item, index) => {
    res.push({
      // fromName: '北京市',
      // toName: item.name,
      value: 10,
      coords: [item.value, [116.405285, 39.904989]]
    })
  })
  return res
}
var series = []
chinaDatas.forEach((item, i) => {

  series.push(
    // 地图版块颜色
    {
      name: '中国',
      type: 'map',
      roam: false,
      geoIndex: 1,
      // itemStyle: {
      //   normal: {
      //     color: '#EAF0F0' //移入前的颜色
      //   },
      //   emphasis: {
      //     color: '#EAF0F0' //移入后的颜色
      //   }
      // },

      data: item.map((dataItem) => {
        return {
          name: dataItem.name
        }
      })
    },

    {
      type: 'lines', // 线数据
      zlevel: 3,
      effect: {
        show: true,
        period: 8, // 箭头指向速度，值越小速度越快
        trailLength: 0, // 特效尾迹的长度。取从 0 到 1 的值，数值越大尾迹越长。
        symbol: 'arrow', // 特效图形的标记。
        symbolSize: 5 // 特效标记的大小
      },
      lineStyle: {
        width: 1, // 尾迹线条宽度
        // color: '#ff0000', // 线颜色
        opacity: 0.3, // 尾迹线条透明度
        curveness: 0.2 // 边的曲度，支持从 0 到 1 的值，值越大曲度越大。
      },
      data: convertData(item)
    },
    {
      type: 'effectScatter',
      coordinateSystem: 'geo',
      zlevel: 3,
      rippleEffect: {
        //涟漪特效
        period: 4, //动画时间，值越小速度越快
        brushType: 'stroke', //波纹绘制方式 stroke, fill
        scale: 7 //波纹圆环最大限制，值越大波纹越大
      },
      label: {
        show: true,
        position: 'right', //显示位置
        offset: [5, 0], //偏移设置
        formatter: function (params) {
          //圆环显示文字
          if(params.data.name=="海南"){
            return params.data.name
          }else{
            return "";
          }
        },
        fontSize: 13,
        color: '#333333'
      },
      symbol: 'circle',
      symbolSize: function (val) {
        return 5 + val[2] * 5 //圆环大小
      },
      showEffectOn: 'render',
      itemStyle: {
        show: false,
        color: '#ff0000',
        shadowBlur: 0,
        shadowColor: '#ff0000'
      },
      data: item.map(function (dataItem) {
        return {
          name: dataItem.name, //  dataItem.name
          value: dataItem.value.concat(0.7)
        }
      })
    }
  )
  if(item[0].name="海南"){
  
  }
})

var option = {
  tooltip: { transitionDuration: 0 },
  visualMap: {
    //板块区分颜色
    // type: 'piecewise',
    show: false,
    color: '#0f0',
    visibility: 'off',
    backgroundColor: '#24c5d5'
  },
  geo: [
    //地图坐标
    {
      map: '中国',
      label: {
        show: true,
        color: '#293e73',
        fontSize: 12,
        fontWeight: 'bold'
      },
      roam: false,
      top: -30,
      // bottom: 16,
      left: 500,
      itemStyle: {
        areaColor: {
          type: 'radial',
          // x: 0.5,
          // y: 0.5,
          r: 1,
          colorStops: [
            {
              offset: 0,
              color: '#6086f2' // 0% 处的颜色
            },
            {
              offset: 1,
              color: '#6086f2' // 100% 处的颜色
            }
          ],
          global: true // 缺省为 false
        },
        borderColor: '#0087FF',
        borderWidth: 1,
        // normal: { label: { show: true } },
        emphasis: {
          color: '#6e7eb8', //移入后的颜色
          // areaColor: '#3066ba',//鼠标滑过区域颜色
          label: {
            textStyle: {
              color: '#ffffff'
            }
          }
        }
      },

      zoom: 1.6,
      center: [116.405285, 39.904989]
    }
  ],
  series
}
export default option
