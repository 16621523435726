<template>
  <div class="download">
    <div class="content">
      <!-- APP下载 -->
      <div class="showAPP">
        <div class="APP">
          <img src="@/assets/image/1.png" alt="" />
        </div>
      </div>
      <div class="showLink">
        <div class="row top">
          <div class="right">
            <a class="Android" :href="andurl" :download="andurl">Android下载</a>
            <a
              class="iPhone"
              :href="iosurl"
              :download="iosurl"
              style="margin-top: 30px;margin-bottom: 10px;"
              >iPhone下载</a
            >
            <div>开发者：北京六识问学教育科技有限公司</div>
            <a>
            <span @click="look(5)">隐私政策</span>
            </a>
            <a>
            <span @click="look(6)">&nbsp;&nbsp;应用权限</span>
            </a>
          </div>
          <div class="QRcode">
            <qrcode-vue
              :margin="2"
              :value="url"
              :size="145"
              level="H"
            ></qrcode-vue>
          </div>
        </div>
        <div class="row bottom">
          <div>
            <div>
              <img src="@/assets/image/z30.png" alt="" />
            </div>
            <div>离线下载</div>
          </div>
          <div>
            <div>
              <img src="@/assets/image/z31.png" alt="" />
            </div>
            <div>倍速播放</div>
          </div>
          <div>
            <div>
              <img src="@/assets/image/z32.png" alt="" />
            </div>
            <div>听课进度同步</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import QrcodeVue from 'qrcode.vue'
import { getItem } from '../../utils/storage'
import { getAPPDownloadList } from '../../api/index'
import { useRouter } from 'vue-router'
const url = location.href
const router = useRouter()
const andurl = ref(getItem('android.download'))
const iosurl = ref(getItem('ios.download'))
getAPPDownloadList().then((res) => {
  res.rows.forEach((item) => {
    if (item.appPlatform == 1) {
      andurl.value = item.downloadUrl
    } else {
      iosurl.value = item.downloadUrl
    }
  })
})
const look = (id) => {
  router.push('/declare?id=' + id)
}
</script>
<style lang="scss" scoped>
@import '@/common/rem';
.download {
  color: #ffffff;
  min-width: 1200px;
  background: url('../../assets/image/stw_500604745.png') no-repeat;
  .content {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    justify-content: space-between;
    padding: rem(100) 0;
    .showAPP {
      flex-grow: 1;

      .APP {
        width: 252px;
        height: 503px;
        margin: 0 auto;
        border-radius: rem(30);
      }
    }
    .showLink {
      width: 252px;
      flex-grow: 1;
      height: 503px;
      div {
        margin-top: rem(30);
      }
      .top {
        justify-content: space-between;
        .right {
          .Android,
          .iPhone {
            width: rem(250);
            display: block;
            height: rem(60);
            font-size: rem(20);
            line-height: rem(60);
            text-align: center;
            border-radius: 30px;
            background: #293e73;
            font-weight: bold;
          }
          .iPhone {
            background: #6e7eb8;
          }
        }
        .QRcode {
          width: 145px;
          height: 145px;
          // border: 7px solid #ffffff;
        }
      }
      .bottom {
        justify-content: space-between;
        > div {
          margin: 0;
          text-align: center;
          div {
            margin: 0;
            &:first-child {
              width: 102px;
              height: 102px;
              box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.3);
              border-radius: 10px;
            }
            &:last-child {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
