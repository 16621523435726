<template>
  <div class="carousel_top">
    <el-carousel
      v-if="swipeList.length > 0"
      :height="bannerHeight + 'px'"
      :interval="5000"
    >
      <el-carousel-item v-for="item in swipeList" :key="item.id">
        <img
          ref="imageba"
          :src="item.imgUrl"
          :alt="item.bannerTitle"
          class="pointer"
          @click="gourl(item.bannerUrl)"
          @load="imageLoad"
        />
      </el-carousel-item>
    </el-carousel>
  </div>
  <!-- 课程分类 -->
  <div class="curriculum-list">
    <div class="title">
      <h3 style="margin-left: 13px;">课程分类</h3>
      <a>
      <span style="margin-right: 14px;" @click="router.push({ path: '/curriculum' })">更多></span>
      </a>
    </div>
    <div class="category">
      <!-- 这一部分 -->
      <el-card v-for="c in coursesList" :key="c.id">
        <template #header class="omit">
          <img :src="c.cover" :alt="c.categoryName" />
          <div class="omit">{{ c.categoryName }}</div></template
        >
        <div
          v-for="(o, index) in c.children"
          @click="lookCourse(o.id)"
          :key="index"
          class="text item row item-content pointer"
          :class="{ 'ma-to-10': index !== 0 }"
        >
          <img :src="o.cover" :alt="o.courseName" />
          <div style="padding-left: 10px">
            <div
              class="title omit"
              style="font-size: 18px; line-height: 2; width: 6em;margin-top: 5px;"
            >
              <div
                style="
                  display: inline-block;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  font-size: 14px;
                  
                "
                v-html="o.courseName"
              ></div>
            </div>
            <!-- 这个o.courseName -->
            <div
              class="subTitle omit2"
              style="color: #666"
              v-html="o.slogan"
            ></div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
  <!-- 讲师列表 -->
  <div class="lecturer row">
    <div class="postion">
      <div class="right_title">专业</div>
      <div class="right_content">职业考试业内人气讲师，事半功倍</div>
    </div>
    <div class="image">
      <img src="@/assets/image/123.png" alt="" />
    </div>
  </div>
  <!-- banner图 -->
  <div class="banner">
    <div class="title">口碑好</div>
    <div class="ftitle">31个省（自治区、直辖市）、数十万学员共同的选择</div>
    <!--地图-->
    <div class="mapbox image" id="ChinaMap" ref="mapRef"></div>
  </div>
  <!-- 产品宣传 -->
  <div class="product">
    <div class="title">产品好</div>
    <div class="ftitle">用互联网技术与运营，模式改造传统教育</div>
    <div class="cards">
      <div v-for="p in productList" :key="p.id">
        <div class="top row juc aic">{{ p.title }}</div>
        <ul class="bottom col jus" style="margin:10px">
          <li class="row aic" v-for="item in p.children" :key="item.id">
            <span></span>
            {{ item.title }}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- 品牌宣传 -->
  <div class="brand">
    <div class="title">品牌好</div>
    <div class="ftitle">强强联合，实现在线教育学习效果和效率的换代提升</div>
    <div class="brochure">
      <ul>
        <el-button color="#6e7eb8" plain  @click="brochureClick(0)" size="large" style="width: 210px;height: 42px;font-size: 18px;" round>
          合作院校
        </el-button>
        <el-button color="#6e7eb8" plain @click="brochureClick(1)" size="large" style="width: 210px;height: 42px;font-size: 18px;" round>
          合作机构
        </el-button>
        <el-button color="#6e7eb8" plain @click="brochureClick(2)" size="large" style="width: 210px;height: 42px;font-size: 18px;" round>
          合作名企
        </el-button>
        <!-- <li @click="brochureClick(0)">合作院校</li>
        <li @click="brochureClick(1)">合作机构</li>
        <li @click="brochureClick(2)">合作名企</li> -->
      </ul>
      <div class="content">
        <el-card
          :body-style="{ padding: '0px' }"
          v-for="i in partnerList"
          @click="gourl(i.cooUrl)"
          :key="i"
        >
 
          <img class="imgdong" style="width: 280px; height: 180px" :src="i.cooImg" alt="图片" />
        
          <div>{{ i.cooTitle }}</div>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue'
import { getBanner, getPartner } from '@/api/index'
import { getProjectList, getCourseList } from '@/api/curriculum'
import { useRouter } from 'vue-router'
import { getItem } from '../../utils/storage'
import * as echarts from 'echarts'
import ChinaMapJson from './china.json'
import mapOption from './map.js'
const bannerHeight = ref('')
const indexScreenWidth = ref('')
 const imageba = ref(null);
const router = useRouter()
const coursesList = ref([])
const swipeList = ref([])
const partnerList = ref([])
// 页面banner图
getBanner({ statusId: 1 }).then((res) => {
  swipeList.value = res.rows
})
const productList = ref([
  {
    id: 1,
    title: '智能学习平台',
    children: [
      { id: 541, title: '管理学习进度' },
      { id: 541, title: '多终端随时登陆' }
    ]
  },
  {
    id: 2,
    title: '匠心内容',
    children: [
      { id: 541, title: '精心打磨，拆解重构' },
      { id: 541, title: '每天45分钟，搞定1个知识点' }
    ]
  },
  {
    id: 3,
    title: '7x24h助学',
    children: [
      { id: 541, title: '问题0遗留' },
      { id: 541, title: '臻享一对一督学' }
    ]
  }
])
getProjectList({ statusId: 1, recommend: 1 }).then((res) => {
  const data = res.rows || []
  coursesList.value = data.slice(0, 4)
  coursesList.value.forEach((item, index) => {
    getCourseList({
      sortType: 1,
      categoryId1: item.id,
      sortType: 1,
      pageNum: 1,
      pageSize: 3
    }).then((res) => {
      item.children = res.rows
    })
  })
})
const brochureClick = (e) => {
  getPartner({ cooType: e }).then((res) => {
    partnerList.value = res.rows
  })
}
brochureClick(0)
const lookCourse = (id) => {
  router.push({
    path: '/curriculum/details/' + id
  })
}
const gourl = (url) => {
  if (!url) {
    return
  }
  window.open(url)
}
var chart = {}

const mapInit = () => {
  chart = echarts.init(document.getElementById('ChinaMap'))
  console.log(ChinaMapJson);
  console.log(mapOption);
  echarts.registerMap('中国', ChinaMapJson)
  
  chart.setOption(mapOption)
}
const screenSize = () => {
 bannerHeight.value = imageba.value.height
}

    window.onresize = () => {
      return (() => {
                console.log("图宽"+imageba.value.height);
        console.log(document.body.clientWidth);
         bannerHeight.value = imageba.value.height
      })()
    }


const imageLoad = (e) =>{
 bannerHeight.value = e.target.height
}

onMounted(() => {
  setTimeout(() => (document.title = getItem('site.name')), 1000)
  mapInit()
})

</script>

<style lang="scss" scoped>
@import '@/common/rem';
@mixin content {
  width: rem(1200);
  margin: 0 auto;
}
.carousel_top {
  width: 100%;
  min-width: rem(1200);
  background-size: cover;
  background: #013954;
  ::v-deep .el-carousel__indicators--horizontal {
    bottom: 56px !important;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
.subTitle {
  font-size: 14px;
}
.curriculum-list {
  padding: rem(45) 0;
  @include content;
  .title {
    display: flex;
    justify-content: space-between;
    font-size: rem(26);
    font-weight: bold;
    span {
      font-size: rem(16);
      font-weight: 400;
    }
  }
  .category {
    display: flex;
    .el-card {
      width: 23%;
      margin: 0 1%;
      background: #ffffff;
      box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.1);
      border-radius: 10px 10px;
      ::v-deep .el-card__header {
        padding: 0;
        display: flex;
        align-content: center;
        height: 100px;
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
        position: relative;
        img {
          width: 100%;
        }
        div {
          left: 30px;
          top: calc(50% - 0.5em);
          position: absolute;
          width: calc(100% - 30px);
        }
      }
      ::v-deep .el-card__body {
        padding: 10px;
        .item-content {
          width: 100%;
          img {
            height: 80px;
          }
        }
      }
    }
  }
}
.subTitle {
  font-size: 14px;
}
.banner,
.product,
.brand {
  width: 100%;
  min-width: rem(1200);
  font-size: rem(18);
  text-align: center;
  color: #666666;
  padding: rem(60) 0;
  line-height: rem(40);
  .title {
    font-size: rem(46);
    font-weight: bold;
    color: #333333;
  }
  .ftitle{
        margin-bottom: 10px;
  }
  .image {
    height: rem(550);
    @include content;
  }
}
.lecturer {
  width: 100%;
  min-width: rem(1200);
  align-items: center;
  overflow: hidden;
  justify-content: center;
  text-align: center;
  position: relative;
  .postion {
    position: absolute;
    left: 200px;
    .right_title{
      font-size: 46px;
    font-weight: bold;
    color: #333333;
    }
    .right_content {
    font-size: 18px;
    text-align: center;
    color: #666666;
    margin-top: 10px;
    }
  }
  .image {
    height: rem(400);
    width: 100%;
  }
}
.product {
  @include content;
  padding: 0 0 rem(60) 0;
  .cards {
    display: flex;
    justify-content: space-around;
    > div {
      overflow: hidden;
      border-radius: rem(10);
      box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.1);
      .top {
        width: rem(380);
        height: rem(70);
        font-size: rem(28);
        font-weight: bold;
        color: #ffffff;
        background: #6e7eb8;
      }
      .bottom {
        background: #ffffff;
        text-align: left;
        li {
          list-style: disc;
          span {
            $qiu: 10px;
            width: rem($qiu);
            height: rem($qiu);
            display: block;
            margin: auto 20px;
            background: #6e7eb8;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
.brand {
  background: #f3f5f7;
        line-height: rem(50);
        letter-spacing: 1px;
  .brochure {
    @include content;
    ul {
      display: flex;
      line-height: rem(40);
      justify-content: center;
      li {
        margin: 0 rem(20);
        width: rem(230);
        height: rem(40);
        background: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: rem(20);
      }
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .el-card {
        width: rem(280);
        margin-top: rem(20);
        border-radius: rem(10);
        overflow: hidden;
        img {
          width: 100%;
          height: rem(180);
        }
        div {
          height: rem(70);
        }
      }
    }
  }
}
         .imgdong {
            cursor: pointer;
            transition: all 0.6s;
        }

         .imgdong:hover {
            transform: scale(1.2);
        }
        .carousel_top img{
          height: auto;
        }
        .el-carousel__container{
          max-width: 100%;
        }
        .indexBanner{
    overflow: hidden;
}
.indexBannerImg{
    width: 100%;
    height: auto;
}
</style>
