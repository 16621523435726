let iconlist = require.context('../../../icons/svg', false, /\.svg$/)

let arr = []
iconlist.keys().forEach((svgIcon) => {
  let svgPage = /\/[A-Za-z0-9]*/.exec(svgIcon)
  let svgName = /[A-Za-z0-9]+/.exec(svgPage[0])
  let icon = svgName[0].toLocaleLowerCase() || ''
  arr.push(icon)
})

export default (name) => {
  let index = arr.indexOf(name.toLocaleLowerCase())
  if (index) {
    return arr[index]
  } else {
    return 'unknow'
  }
}
