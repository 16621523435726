<template>
  <div class="purchase">
    <!--  -->
    <div class="shopping">
      <div class="title">确认订单</div>
      <div class="order row">
        <div class="showImage">
          <img :src="orderInfo.courseLogo" alt="图片" />
        </div>
        <div style="margin-left: 40px">
          <div style="margin-top: 25px;" class="order_title">{{ orderInfo?.courseName }}</div>
          <div class="order_subtitle">{{ orderInfo?.introduction }}</div>
          <div v-if="orderInfo?.effective">
            有效期：自购买之日起{{
              orderInfo?.effective || 0
            }}天内有效（在此期间可以反复观看）
          </div>
          <div style="margin-top: 20px;    font-size: 14px;
    font-weight: 400;
    color: #666666;
    margin-bottom: 40px;">
           <div style="margin-bottom: 5px;"> 须知：</div>
              1、支付前请确认订单信息
              <br/>
              2、系统默认优先使用学分支付
              <br/>
              3、订单完成无法更改
          </div>
        </div>
      </div>
      <div class="settleAccounts">
        <p>
          商品金额：{{
            orderInfo.isFree
              ? '免费'
              : orderInfo.courseDiscount
              ? orderInfo.courseDiscount
              : orderInfo.courseOriginal
          }}
        </p>
        <p>
          使用积分：{{
            data.isCredit
              ? orderInfo.fraction&&yingfu.toString!='免费'
                ? orderInfo.fraction > parseInt(yingfu)
                  ? parseInt(yingfu)
                  : integral
                : 0
              : '该课程未开启积分抵扣'
          }}
        </p>
        <div class="row">
          <span>共计：{{ yingfu }} </span>
        </div>
      </div>
      <a>
      <div    @click="router.push('/declare?id=8')" class="subtitle" style="line-height: 10px">
        积分/学分支付规则：1个积分/学分等于1块钱人民币
      </div>
      </a>
      <div class="zhifu row">
        <div class="btn" @click="payment()">立即支付</div>
      </div>
    </div>
  </div>
  <el-dialog
    v-model="centerDialogVisible"
    title="立即支付"
    width="400px"
    center
  >
    <div class="content col" style="text-align: center;">
      <el-form>
        <el-form-item label="支付方式">
          <!-- <el-radio v-if="value" v-model="radioValue" label="1">微信</el-radio>
          <el-radio v-if="payResponse" v-model="radioValue" label="2"
            >支付宝</el-radio
          > -->
          <el-radio v-if="qriceImage" v-model="radioValue" label="3"
            >微信支付</el-radio
          >
          <el-radio v-if="qriceImageAli" v-model="radioValue" label="4"
            >支付宝支付</el-radio
          >
          </el-form-item>
      </el-form>
      <!-- <div class="subTitle">支付时请备注姓名手机号等信息</div> -->
      <div class="qrcode">
        <qrcode-vue
          :margin="3"
          :value="value"
          :size="size"
          level="L"
          v-if="radioValue == 1"
        ></qrcode-vue>
        <iframe
          style="height: 210px"
          v-else-if="radioValue == 2"
          :src="payResponse"
        >
        </iframe>
        <img v-else-if="radioValue == 3" :src="qriceImage" alt="" />
        <img v-else-if="radioValue == 4" :src="qriceImageAli" alt="" />
      </div>
      <div class="btn" v-if="radioValue == 3||radioValue==4" @click="pay" 
      style="margin-right: 20px;
        margin-left: 20px;
        margin-bottom: 10px;">我已支付</div>
      <div class="flex">
        <el-checkbox v-model="checked" label="">阅读并同意<span @click="look(3)">《六问课堂购课须知》</span></el-checkbox>
      </div>
    </div>
  </el-dialog>
  <el-dialog
  center ='true'
    v-model="dialogVisible"
    :title="showContent.articleTitle"
    width="900px"
  >
    <div style="margin: 20px;" v-html="showContent.articleContent"></div>
  </el-dialog>
</template>
<script setup>
import { ref, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { addOrder, alterOrder, getOrderStatus } from '@/api/order'
import { getEssayDetail } from '../../../api/index'
import { getItem } from '../../../utils/storage'
import { ElMessageBox, ElMessage } from 'element-plus'
import QrcodeVue from 'qrcode.vue'

const store = useStore()
const router = useRouter()
const urlParams = router.currentRoute.value.params
const centerDialogVisible = ref(false)
const value = ref('')
const payResponse = ref('')
const dialogVisible = ref(false)
const showContent = ref({})
const size = ref(230)
const radioValue = ref('3')
const checked = ref(true)
const qriceImage = ref(getItem('wx.collection.code'))
const qriceImageAli = ref(getItem('ali.collection.code'))
const data = getItem('dfahkhkjsdgfhajhjs')
const orderInfo = ref({
  ...data,
  fraction: store.getters.userInfo.fraction
})
const integral = ref(0)
const yingfu = ref(0)
const id = ref(0)
const add = () => {
  var pricePayable = 0
  var pricePaid = 0
  if (!data.isFree) {
    if (data.isCredit) {
      if (data.courseDiscount) {
        let jiage = data.courseDiscount

        pricePaid = jiage > 0 ? jiage : 0
        pricePaid =
          pricePaid -
          (orderInfo.value.fraction
            ? orderInfo.value.fraction >= parseInt(pricePaid)
              ? parseInt(pricePaid)
              : orderInfo.value.fraction
            : 0)

        pricePayable = data.courseDiscount
      } else if (data.courseOriginal) {
        let yuanjia = data.courseOriginal
        pricePaid = yuanjia > 0 ? yuanjia : 0
        yingfu.value = pricePaid
        pricePaid =
          pricePaid -
          (orderInfo.value.fraction
            ? orderInfo.value.fraction > parseInt(pricePaid)
              ? parseInt(pricePaid)
              : orderInfo.value.fraction
            : 0)
        pricePayable = data.courseOriginal
      }
      yingfu.value = pricePaid
    } else {
      if (data.courseDiscount) {
        let jiage = data.courseDiscount
        pricePaid = jiage > 0 ? jiage : 0

        pricePayable = data.courseDiscount
      } else if (data.courseOriginal) {
        let yuanjia = data.courseOriginal
        pricePaid = yuanjia > 0 ? yuanjia : 0
        yingfu.value = pricePaid

        pricePayable = data.courseOriginal
      }
      yingfu.value = pricePaid
    }
  } else {
    yingfu.value = '免费'
  }
integral.value=pricePayable-pricePaid;
  addOrder({
    courseId: data.id,
    courseName: data.courseName,
    categoryId: data.categoryId1,
    pricePayable,
    deduction: integral.value,
    pricePaid,
    channelType: 1
  }).then((res) => {
    id.value = res.data.id
    // value.value = res.data.codeUrl || ''
    // payResponse.value = res.data.payBody
    // radioValue.value = value.value ? 1 : payResponse.value ? 2 : 3
  })
}
add()

const look = (articleId) => {
  getEssayDetail({ articleId }).then((res) => {
    showContent.value = res.rows[0] || {
      articleTitle: '《六问课堂购课须知》',
      articleContent: ''
    }
    dialogVisible.value = true
  })
}
const onlook = (id) => {
  router.push('/declare?id=' + id)
}
var time = setInterval(() => {
  getOrderStatus({ id: id.value }).then((res) => {
    if (res.orderStatus == 2) {
      clearInterval(time)
      router.push({
        path: '/curriculum/details/' + data.id
      })
      ElMessage({
        type: 'success',
        message: '购买成功'
      })
    }
  })
}, 3000)

onBeforeUnmount(() => {
  clearInterval(time)
})

const payment = () => {
  var status = yingfu.value > 0 ? 5 : 2
  if (data.isFree) {
    status = 2
  }
  if (status == 2) {
    return alterOrder({
      id: id.value,
      orderStatus: status
    }).then((res) => {
      if (res.code === 200) {
        router.push({
          path: '/curriculum/details/' + data.id
        })
      }
    })
  }
  centerDialogVisible.value = true
}
const pay = () => {
  if (checked.value) {
    const status = yingfu.value > 0 ? 5 : 2
    alterOrder({
      id: id.value,
      orderStatus: status
    }).then((res) => {
      if (res.code === 200) {
        if (status === 2) {
          router.push({
            path: '/curriculum/details/' + data.id
          })
        } else {
          ElMessageBox.confirm(
            '当前课程购买成功，等待后台管理员审核',
            '系统提示',
            {
              confirmButtonText: '我知道了',
              cancelButtonText: '取消',
              type: 'success'
            }
          ).then(() => {
            router.push({
              path: '/curriculum/details/' + data.id
            })
          })
        }
      }
    })
  } else {
    ElMessage({
      message: '请确认阅读并以同意《六问课堂购课须知》',
      type: 'error'
    })
  }
}
</script>
<style lang="scss" scoped>
@import '@/common/rem';
.purchase {
  background: #f3f5f7;
  padding: rem(70) 0;
  .shopping {
    width: rem(1200);
    margin: 0 auto;
    background: #ffffff;
    padding: 30px;
    border-radius: rem(10);
    .title {
      text-align: center;
      font-size: 30px;
      color: #293e73;
    }
    .order {
      padding: rem(20);
      margin-top: 20px;
      background: #f3f5f7;
      border-radius: rem(10);
      .showImage {
        min-width: 190px;
        max-width: 400px;
        min-height: 95px;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      &_title {
        font-size: rem(20);
        font-weight: bold;
        color: #333333;
      }
      &_subtitle {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        margin-bottom: rem(20);
        margin-top: 10px;
      }
    }
    .settleAccounts {
      border: 1px solid #ccc;
      border-left: none;
      padding: rem(25) 0;
      border-right: none;
      margin: rem(60) 0 rem(15) 0;
      p {
        margin: 0 0 rem(20) rem(30);
      }
      div {
        justify-content: flex-end;
      }
    }
    .zhifu {
      justify-content: flex-end;
      .btn {
        width: rem(220);
        height: rem(50);
        border-radius: rem(50);
        cursor: pointer;
      }
    }
  }
}
.el-dialog {
  text-align: center;
  .content {
    justify-content: center;
    .qrcode {
      max-width: rem(231);
      margin: rem(30) auto;
      img {
        width: 100%;
      }
      form {
        width: 100%;
      }
    }
  }
}
:root {
  --el-dialog-padding-primary: 40px;
}
</style>
